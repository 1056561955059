/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'sn-instagram': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.451.71C13.256.243 11.11.02 9 0 6.89.019 4.744.243 3.568.692 1.386 1.539.667 3.752.518 4.303c0 .003.002-.003-.007.03a8.063 8.063 0 00-.053.224 18.961 18.961 0 00-.456 4.434c-.018 1.366.085 2.888.442 4.4a20.084 20.084 0 00.075.305l.014.052c.177.627.916 2.737 3.035 3.56 1.176.449 3.323.673 5.432.692 2.11-.019 4.256-.224 5.432-.692 2.181-.847 2.9-3.059 3.05-3.611 0-.003-.002.003.006-.03l.022-.085.032-.14c.35-1.528.474-3.069.456-4.433.018-1.378-.087-2.914-.434-4.42l-.035-.152a7.122 7.122 0 00-.023-.091l-.009-.035c-.155-.571-.88-2.759-3.046-3.6z" _fill="#898B9B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12 4.2H6A1.8 1.8 0 004.2 6v6A1.8 1.8 0 006 13.8h6a1.8 1.8 0 001.8-1.8V6A1.8 1.8 0 0012 4.2zM6 3a3 3 0 00-3 3v6a3 3 0 003 3h6a3 3 0 003-3V6a3 3 0 00-3-3H6zm3 7.8a1.8 1.8 0 100-3.6 1.8 1.8 0 000 3.6zM9 12a3 3 0 100-6 3 3 0 000 6zm3.25-5.5a.75.75 0 100-1.5.75.75 0 000 1.5z" _fill="#fff"/>'
  }
})
